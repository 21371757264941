var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"container pa-5 mt-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isInRole('41'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("add"))+" "),_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("prescriptions.item")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
                            function (value) { return !!value || _vm.$t('ThisFieldIsRequired'); } ],"label":_vm.$t('prescriptions.itemName')},model:{value:(_vm.editedItem.itemName),callback:function ($$v) {_vm.$set(_vm.editedItem, "itemName", $$v)},expression:"editedItem.itemName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('prescriptions.brandName')},model:{value:(_vm.editedItem.brandName),callback:function ($$v) {_vm.$set(_vm.editedItem, "brandName", $$v)},expression:"editedItem.brandName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('notes')},model:{value:(_vm.editedItem.note),callback:function ($$v) {_vm.$set(_vm.editedItem, "note", $$v)},expression:"editedItem.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[(_vm.isInRole('42'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit")))])]):_vm._e(),(_vm.isInRole('43'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red darken-2"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])]):_vm._e()],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }