<template>
  <v-container fluid>
    <v-card class="container pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="5"
        class=""
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isInRole('41')"
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("add") }}

                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-toolbar color="primary darken-1" dark>
                    <v-card-title>
                      <span class="">{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                          " " +
                          $t("prescriptions.item")
                      }}</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.itemName"
                            :rules="[
                              (value) => !!value || $t('ThisFieldIsRequired'),
                            ]"
                            :label="$t('prescriptions.itemName')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.brandName"
                            :label="$t('prescriptions.brandName')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.note"
                            :label="$t('notes')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="green darken-1"
                      class="white--text"
                      @click="save"
                      :disabled="!valid"
                      :min-width="100"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                      {{ $t("save") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="close">
                      {{ $t("cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>

            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="isInRole('42')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="isInRole('43')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red darken-2"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      headers: [
        { text: this.$t("prescriptions.itemName"), value: "itemName" },
        { text: this.$t("prescriptions.brandName"), value: "brandName" },
        { text: this.$t("notes"), value: "note" },
        { text: "", value: "actions" },
      ],
      items: [],
      editedItem: {
        itemName: "",
        brandName: "",
        note: "",
      },
      defaultItem: {
        itemName: "",
        brandName: "",
        note: "",
      },
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length <= 5) || "Max 5 characters",
      ],
    };
  },
  created() {
    this.refreshTable();
  },
  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.items[this.editedIndex];

      axios
        .delete("PrescriptionItems/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.data.status == "Successful") {
            this.$toast(this.$t("operationAccomplishedSuccessfully"));
          } else {
            this.$toast.error(this.$t("error." + response.data.message));
          }

          this.refreshTable();
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios.get("PrescriptionItems").then((response) => {
        this.items = response.data.data;
      });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.items[this.editedIndex];

          updatedItem.itemName = this.editedItem.itemName;
          updatedItem.brandName = this.editedItem.brandName;
          updatedItem.note = this.editedItem.note;

          axios
            .post("PrescriptionItems/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        } else {
          axios
            .post("PrescriptionItems/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              this.refreshTable();
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style></style>
